import { createContext } from 'react'
import Api, { IApi } from '@api'
import { action, makeAutoObservable } from 'mobx'
import { ProjectStore } from './project.stores'
import { saveToStorage } from '@helpers/browser-storage.helpers'
import { AuthStore } from './auth.stores'
import { AccountStore } from './account.stores'

export class Store {
  api: IApi
  projects: ProjectStore
  auth: AuthStore
  account: AccountStore

  constructor() {
    makeAutoObservable(this)
    this.api = Api()
    this.projects = new ProjectStore(this)
    this.auth = new AuthStore(this)
    this.account = new AccountStore(this)
  }

  @action
  set<S extends keyof Store, K extends keyof Store[S], A extends Store[S][K]>(
    store: S,
    key: K,
    value: A,
    save?: boolean
  ) {
    if (!key || !store) return
    ;(this as Store)[store][key] = value
    if (save) saveToStorage(key as string, value)
  }
}

export const StoreContext = createContext<Store>(new Store())
