import { INavItem } from '@typings'
import { ICONS } from './icons.constants'
import { LABELS } from './labels.constants'
import { ROUTE_PATHS } from './routes.constants'

export const NAVIGATION_MAIN: INavItem[] = [
  {
    label: LABELS.HOME,
    to: ROUTE_PATHS.HOME,
    icon: ICONS.HOME,
  },
]
