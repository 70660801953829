import { ICONS } from '@constants'
import { handleKeyDown } from '@helpers/handle-key-down.helper'
import clsx from 'clsx'
import { useMemo } from 'react'
import { AcIcon, AcTooltip } from '../index.core.component'
import styles from './ac-text-button.module.scss'

interface IAcTextButtonProps {
  withTooltip?: boolean
  icon?: keyof typeof ICONS
  mode?: 'light' | 'dark'
  className?: string
  disabled?: boolean
  label: string
  onClick: () => void
}

export const AcTextButton = ({
  icon,
  mode,
  className,
  disabled,
  label,
  onClick,
  withTooltip,
}: IAcTextButtonProps) => {
  const getButtonClassNames = useMemo(
    () =>
      clsx(
        styles['ac-text-button'],
        styles[`ac-text-button-${mode}`],
        icon && styles[`ac-text-button-${mode}--has-icon`],
        disabled && styles[`ac-text-button-${mode}--disabled`],
        className && className
      ),
    [label, icon, className, disabled]
  )

  const getMainLabelClasses = useMemo(
    () =>
      clsx(
        styles['ac-text-button-label'],
        styles['ac-text-button-label--main']
      ),
    []
  )

  const getSecondaryLabelClasses = useMemo(
    () =>
      clsx(
        styles['ac-text-button-label'],
        styles['ac-text-button-label--secondary']
      ),
    []
  )

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClick && onClick()
  }

  return (
    <AcTooltip
      visible={withTooltip}
      label={label}>
      <button
        onClick={handleClick}
        onKeyDown={e => handleKeyDown(e, () => handleClick)}
        aria-label={label}
        className={getButtonClassNames}>
        <span className={styles['ac-text-button-inner']}>
          {icon && (
            <AcIcon
              className={styles['ac-text-button-icon']}
              icon={icon}
            />
          )}
          <div className={styles['ac-text-button-label-wrapper']}>
            <span
              className={getMainLabelClasses}>
              {label}
            </span>
            <span
              className={getSecondaryLabelClasses}>
              {label}
            </span>
          </div>
        </span>
      </button>
    </AcTooltip>
  )
}
