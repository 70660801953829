export const LABELS = {
  ACATO_CLIENT_PORTAL: 'My Acato',
  ACCOUNT: 'Account',
  BACK: 'Terug',
  BACK_TO_OVERVIEW: 'Terug naar overzicht',
  DEADLINE: 'Deadline',
  COMPANY: 'Bedrijf',
  CHANGES: 'Aanpassingen',
  CONTACT: 'Contact',
  CONTACT_INFO: 'Contactgegevens',
  DESCRIPTION: 'Omschrijving',
  EMAIL: 'E-mail',
  FORGOT_PASSWORD: 'Wachtwoord vergeten',
  GENERAL: 'Algemeen',
  GET_IN_TOUCH: 'Neem contact op',
  HOME: 'Home',
  HOUR: 'uur',
  HOURS_TOTAL: 'uur totaal',
  HOURS_OPEN: 'Openstaand',
  NAME: 'Naam',
  NEXT: 'Volgende',
  LOGIN: 'Inloggen',
  LOGOUT: 'Uitloggen',
  ONSET: 'Aanvang',
  OVERVIEW: 'Overzicht',
  PHONE: 'Telefoon',
  PROJECTS: 'Projecten',
  PREVIOUS: 'Terug',
  PLANNING: 'Planning',
  PLANNED: 'Ingepland',
  READ_MORE: 'Lees meer',
  READ_LESS: 'Lees minder',
  WRITTEN: 'Geschreven',
  WRITTEN_HOURS: 'Geschreven uren',
} as const

export const DYNAMIC_LABELS = {
  N_HOUR: (number: number) => `${number} uur`,
  HELLO_X: (name: string) => `Hello, ${name}`,
  UNTILL_WEEK_N: (number: number) => `t/m week ${number}`,
} as const
