import { AcLoader } from '@components'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { useTrail, a } from '@react-spring/web'
import styles from './ac-container.module.scss'

interface IAcContainer {
  children: React.ReactNode
  className?: string
  loading?: boolean
  title?: string
  element?: 'div' | 'section'
  padding?: 'none' | 'default'
}

export const AcContainer = ({
  children,
  element: Element = 'div',
  loading,
  padding = 'default',
}: IAcContainer) => {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200, precision: 0.0001, clamp: true },
    opacity: loading ? 0 : 1,
    y: loading ? 10 : 0,
    from: { opacity: 0, y: 10 },
  })
  const getContainerClasses = useMemo(
    () =>
      clsx(
        styles['ac-container'],
        padding && styles[`ac-container-padding-${padding}`]
      ),
    [padding]
  )
  return loading ? (
    <AcLoader />
  ) : (
    <Element className={getContainerClasses}>
      {trail.map((style, index) => (
        <a.div
          key={index}
          style={{ ...style }}>
          {items[index]}
        </a.div>
      ))}
      {/* {children} */}
    </Element>
  )
}
