import { AcTitle, AcTypography } from '../index.core.component'
import styles from './ac-panel-header.module.scss'

interface IAcPanelHeader {
  panelPreTitle?: string
  panelTitle?: string
}

export const AcPanelHeader = ({
  panelPreTitle,
  panelTitle,
}: IAcPanelHeader) => {
  return (
    <header className={styles['ac-panel__header']}>
      <AcTypography
        weight="light"
        color="neutral-200"
        className={styles['ac-panel__pre-title']}>
        {panelPreTitle}
      </AcTypography>
      <AcTitle
        level={5}
        className={styles['ac-panel__title']}>
        {panelTitle}
      </AcTitle>
    </header>
  )
}
