import { AccountApi } from './account.api'
import { AuthApi } from './auth.api'
import { ProjectsApi } from './projects.api'
export { BaseApi } from './_base.api'

export interface IApi {
  auth: AuthApi
  projects: ProjectsApi
  account: AccountApi
}

export default (): IApi => {
  const auth = new AuthApi()
  const projects = new ProjectsApi()
  const account = new AccountApi()

  return { projects, account, auth }
}
