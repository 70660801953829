export const KEYS = {
  ACCESS_TOKEN: 'access_token',
  HOME: 'home',
  NEW_TOKEN: 'newtoken',
  COMPONENTS: 'components',
  EXAMPLE: 'example',
  PROJECT_LIST: 'project',
  PROJECT_DETAIL: 'projectdetail',
  PRIMARY: 'primary',
  ACCOUNT: 'account',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  LOGIN: 'login',
  LOGIN_WITH_TOKEN: 'loginwithtoken',
  UNAUTH: 'unauth',
} as const

export const STORES = {
  API: 'api',
  PROJECTS: 'projects',
  AUTH: 'auth',
  ACCOUNT: 'account',
} as const

export const STORE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  LOADING: 'loading',
  SINGLE: 'single',
  USER: 'user',
  PLANNING: 'planning',
  PLANNING_LOADING: 'planningLoading',
  MESSAGE: 'message',
  ALL: 'all',
} as const
