import {
  AcAppHeader,
  AcContactCard,
  AcContainer,
  AcGridContainer,
  AcProjectCard,
  AcTitle,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useSetDocumentTitle } from '@hooks/use-set-document-title'
import { useStore } from '@hooks/use-store'
import { a, useSpring, useTrail } from '@react-spring/web'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

export const ProjectListView = observer((): JSX.Element => {
  const { projects, account } = useStore()

  const springConfig = {
    mass: 5,
    tension: 2000,
    friction: 250,
    precision: 0.0001,
    clamp: true,
  }

  const [contactStyle] = useSpring(
    () => ({
      config: {
        mass: 7,
        tension: 2000,
        friction: 800,
        precision: 0.0001,
      },
      from: { opacity: 0, y: 50 },
      to: { opacity: 1, y: 0 },
      delay: 700,
    }),
    []
  )

  const projectsTrail = useTrail(projects.all.length, {
    config: springConfig,
    opacity: 0,
    y: 30,
    from: { opacity: 0, y: 30 },
    to: { opacity: 1, y: 0 },
    delay: 200,
  })

  const hasProjects = useMemo(
    () => projects.all && !!projects.all.length,
    [projects.all]
  )

  useEffect(() => {
    projects.getList()
    return () => projects.resetOverview()
  }, [])

  useSetDocumentTitle(LABELS.OVERVIEW)

  return (
    <>
      <AcAppHeader withGreeting />
      <AcContainer
        loading={projects.loading}
        padding="none">
        {!hasProjects && (
          <>
            <AcGridContainer columns={{ md: 2, lg: 2 }}>
              <div style={{ marginBottom: '1rem' }}>
                <AcTitle
                  level={5}
                  withBottomMargin>
                  Er zijn nog geen projecten
                </AcTitle>
                <AcTypography>
                  Het lijkt erop dat er nog geen (actieve) projecten zijn. Klopt
                  dit niet? Neem contact op met jouw Acato contactpersoon
                </AcTypography>
              </div>
              {account.current_acato_contact && (
                <AcContactCard {...account.current_acato_contact} />
              )}
            </AcGridContainer>
          </>
        )}

        {hasProjects && (
          <>
            <AcTitle
              level={4}
              withBottomMargin>
              {LABELS.PROJECTS}
            </AcTitle>
            <AcGridContainer>
              {hasProjects &&
                projectsTrail.map(({ ...style }, i) => (
                  <a.div
                    key={i}
                    style={{ ...style }}>
                    <AcProjectCard {...projects.all[i]} />
                  </a.div>
                ))}
              {account.current_acato_contact && (
                <a.div style={{ ...contactStyle }}>
                  <AcContactCard {...account.current_acato_contact} />
                </a.div>
              )}
            </AcGridContainer>
          </>
        )}
      </AcContainer>
    </>
  )
})
